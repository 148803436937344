/* BlinkingText.css */
@keyframes blink {
    50% {
        opacity: 0;
    }
}

.blinking-text {
    animation: blink 2s linear infinite;
}
